<template>
<section>
	<button class="pillButton StickySaveButton" @click="sendMessage()">Send to this List</button>

	<div class="flex-row flex-gap-2">
		<div>
			<div>Name: <input type="text" v-model="list.name" /></div>
			<div>Description: <textarea v-model="list.description" /></div>
		</div>

		<div>
			<div>Custom columns:</div>
			<div class="flex-row flex-align-center flex-gap-1">
				<TagField :Source="list.mailMergeTags" :topMargin="false" @remove="tag => showDeleteTag( tag )" />
				<button class="pillButton secondary" @click="showAddTag()"><span class="icon-plus" /> New Column</button>
			</div>
		</div>
	</div>

	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<span class="icon-file-excel font-size-1-5" @click="csvDownload()" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this Blast List`" />
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:superColumns='superColumns'
				:Numbered='true'
				:inlineSelect='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='true'
				@inlineEdit="( item, propName, value ) => edit( item, propName, value )"
				@sort='prop => sort( prop )'
				@delete='item => showDeleteSelected( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<ConfirmDialog ref="confirmDeleteRow" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>{{ itemToDelete.name || itemToDelete.email || itemToDelete.phone }}</b>?</div>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmDeleteTag" @cancel="tagToDelete = null" @confirm="deleteTag()">
		<div v-if="tagToDelete">Really delete column <b>{{ tagToDelete }}</b>?</div>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmAddTag" @cancel="tagToAdd = null" @confirm="addTag()">
		<div>Column Name: <input type="text" v-model="tagToAdd" /></div>
	</ConfirmDialog>

</section>
</template>



<script>
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import TagField from '@/components/utilities/TagField.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import BlastList from './BlastList.js'
import BlastListRow from './BlastListRow.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'BlastListDetails',


	components: {
		SearchBox,
		ObjectTable,
		Paginator,
		ConfirmDialog,
		TagField,
	},


	props: {
		list: {
			type: BlastList,
			required: true
		},
	},


	
	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: null,
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: false,

			selectedItem: null,
			itemToDelete: null,

			tagToAdd: null,
			tagToDelete: null,

			columns: [],
			superColumns: null
		}
	},



	computed: {
		t() { return Tooltips; },
	},



	watch: {
		list() { this.page = 1; this.initialize() },
		pageSize() { this.page = 1; this.getListRows() },
		searchString() { this.page = 1; this.getListRows() },
	},


	created() { this.initialize() },


	methods: {

		async initialize() {
			
			// Init columns
			this.columns = [
				{
					displayName: 'Name',
					propertyName: 'name',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'POC ID',
					propertyName: 'pocID',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'E-Mail',
					propertyName: 'email',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'Phone',
					propertyName: 'phone',
					sortable: true,
					editable: true,
				},
			]

			// Close over colName:
			// Must generate each column's display function to lexically bind colName w/in the for-of loop
			//  -- otherwise, call-time value will be used, which is *always* the name of the last column.
			const generateDisplayFunction = function( colName ) {
				return function( row ) {
					const col = row.extraColumns.find( elem => elem.key == colName )
					return col ? col.value : null
				}
			}

			var superColWidth = 0

			for( var colName of this.list.mailMergeTags ) {
				superColWidth++

				this.columns.push({
					displayName: colName,
					propertyName: colName,
					displayFunction: generateDisplayFunction( colName ),
					editable: true,
				})
			}

			this.columns.push({
				displayName: 'Date Created',
				propertyName: 'dateCreated',
				displayFunction: ( item ) => { return item.dateCreated ? item.dateCreated.toLocaleString() : null },
				sortable: true,
			})

			if( superColWidth > 0 ) this.superColumns = [ { displayName: 'Default Columns', colspan: 5 }, { displayName: 'Custom Columns', colspan: superColWidth }, { displayName: '', colspan: 1 } ]
			
			await this.getListRows()
		},


		async getListRows() {
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const pr = await MessageCenterAPI.getBlastListRows( this.list.id, req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( BlastListRow.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}
		},


		sendMessage() {
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { listIn: this.list }, header: `Compose Message` } )
		},


		async edit( item, propertyName, value ) {
			const defaultProps = [ 'userID', 'pocID', 'name', 'email', 'phone' ]
			
			if( defaultProps.includes( propertyName ) )	item[ propertyName ] = value
			else item.setExtraColumn( propertyName, value )

			await MessageCenterAPI.patchBlastListRow( { rowID: item.rowID, listID: item.listID, propertyName, value } )
		},


		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.getListRows()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'dateCreated'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.getListRows()
		},


		csvDownload() {
			MessageCenterAPI.getBlastListCSV( this.list.id )
		},


		showDeleteSelected( item ) {
			this.itemToDelete = item
			this.$refs.confirmDeleteRow.open()
		},


		async deleteItem() {
			const item = this.itemToDelete

			if( !item.rowID ) return
			await MessageCenterAPI.deleteBlastListRow( this.list.id, item.rowID )
			
			const idx = this.items.findIndex( elem => elem.rowID == item.rowID )
			if( idx < 0 ) return
			this.items.splice( idx, 1 )
			this.list.numContacts--

			this.itemToDelete = null
		},


		showAddTag() {
			this.$refs.confirmAddTag.open()
		},


		async addTag() {
			if( !this.tagToAdd ) return
			await MessageCenterAPI.addBlastListColumn( this.list.id, this.tagToAdd )
			this.list.mailMergeTags.push( this.tagToAdd )
			this.tagToAdd = null
			this.initialize()
		},


		showDeleteTag( tag ) {
			this.tagToDelete = tag
			this.$refs.confirmDeleteTag.open()
		},


		async deleteTag() {
			await MessageCenterAPI.deleteBlastListColumn( this.list.id, this.tagToDelete )
			this.list.mailMergeTags = this.list.mailMergeTags.filter( tag => tag !== this.tagToDelete )
			this.tagToDelete = null
			this.initialize()
		}

	}

}
</script>



<style scoped>

</style>