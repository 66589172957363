<template>
<section style="display:inline-block;">
  <form class='search-bar' @submit.prevent='$emit("search", searchString)'>
    <input
      type="text"
      :placeholder="placeholder"
      v-model="searchString"
      :style="styles"
      @focus='$emit("focus")'
      @blur='$emit("blur")'
      @change='$emit("dynamicSearch", searchString)'
      @keypress='$emit("dynamicSearch", searchString)'
      @paste='$emit("dynamicSearch", searchString)'
      @input='$emit("dynamicSearch", searchString)'
      @keyup.enter='$emit("search", searchString)'
    />
    <span @click='$emit("search", searchString)' class="icon-search button-search" />
  </form>
</section>
</template>

<script>
export default {
  name: 'SearchBox',

  props: {
    placeholder: {
      type: String,
      default: "Search..."
    },
    
    styles: {
      type: Object,
      default: () => { return {} }
    }
  },

  data() {
    return {
      searchString: '',
    }
  },


}
</script>

<style scoped>
.search-bar {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
	white-space: nowrap;
}
.search-bar input {
  border: none;
  font-size: 12pt;
  margin: .25em;
  transition: all 0.5s;
}
</style>
