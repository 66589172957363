<template>
<section style="width: 100%;">
	<p v-if="isTemplate">Built-in Block</p>
	

	<div class='flex-column' style="width: 100%;">
		<div class="flex-row flex-justify-between">
			<input v-if="isTemplate" type="text" v-model="section.name" placeholder="Block Label (admin)" />
			<div v-else>{{ section.name }}<span style="color: #888; font-size: 0.8em;"> - Built-in ({{ section.componentName }})</span></div>
			<div v-if="!isTemplate && !isDefault" id="revert" @click="revert"><span class="icon-undo" /> Revert</div>
		</div>
	</div>

	<div v-if="isTemplate" style="width: 100%;">
		Built-in component:
		<select v-model="section.componentName">
			<option value="HeroStore">Hero Banner Storefront</option>
			<option value="FactoidWidgets">Factoid Widgets &amp; Counters</option>
			<option value="EducatorComments">Educator Comments Slider</option>
			<option value="SuperComments">Super Comments Slider</option>
			<option value="StudentSpotlight">Student Spotlight</option>
			<option value="ScoresAndScholarships">Raised Scores &amp; Scholarships</option>
			<option value="OurScores">Our Scores (99th percentile)</option>
			<option value="VideoTestimonials">Video Testimonials</option>
		</select>
	</div>


	<!-- HeroStore settings -->
	<div v-if="section.componentName == 'HeroStore'" class="mt-2 border-top pt-2 mb-5" style="width: 100%;">
		
		<div v-if="!isTemplate && affiliateID" class="flex-row flex-align-center flex-gap-3">
			<div class="flex-row flex-align-center flex-gap-05"><span>Aff Short Name:</span><input type="text" class="flex-grow" v-model="section.vars.shortName" @input="nullifyBlank('shortName')" placeholder="Name" /></div>
			<div class="flex-row flex-align-center flex-gap-05 flex-grow"><span>Greeting:</span><input type="text" class="flex-grow" v-model="section.vars.greeting" @input="nullifyBlank('greeting')" placeholder="Greeting: Welcome Students & Families" /></div>
		</div>
		
		<div class="flex-row flex-align-center flex-gap-3">
			<div class="flex-row flex-align-center flex-gap-1">
				<span>Background color: <input type="text" v-model="section.vars.bgColor" @input="nullifyBlank('bgColor')" placeholder="#70dfdb" /></span>
				<div :style="`min-height: 2em; min-width: 2em; background-color: ${ section.vars.bgColor ? section.vars.bgColor : '#70dfdb' };`"></div>
			</div>
			<div class="flex-row flex-align-center flex-gap-05 flex-grow"><span>"Person" Image URL:</span><input type="text" class="flex-grow" v-model="section.vars.mainImageURL" @input="nullifyBlank('mainImageURL')" placeholder="Use Default" /></div>
		</div>
		
		<div v-if="!isTemplate && affiliateID" class="mt-1">
			<h2>Logo:</h2>
			<AttachmentFileViewer hostType="affiliate" :hostID="affiliateID" :select="true" v-model="section.vars.logoFileID" />
		</div>

		<div v-if="!isTemplate && affiliateID" class="mt-3">
			<h2>Affiliate Quote:</h2>
			<Tiptap 
				class="flex-grow"
				:content="section.vars.quote"
				:saveDelayMS="500"
				:saved="tiptapSaved"
				:allowedShortcodes="{}"
				:loadingShortcodes="false"
				:shortcodesError="false"
				:allowNodeLinkShortcode="false"
				:hostID="affiliateID"
				hostType="affiliate"
				@update="tiptapSaved = false"
				@sendContentAsHTML="html => tiptapSave(html)"
				ref="tiptap"
			/>
		</div>
		<div v-if="!isTemplate && affiliateID" class="mt-1">
			<div>Select Quote Author Image:</div>
			<AttachmentFileViewer hostType="affiliate" :hostID="affiliateID" :select="true" v-model="section.vars.quoteImageFileID" />
		</div>
	</div>
	

	<div v-if="section.subsection" class="mt-2 border-top pt-2" style="width: 100%;">
		<h2>Storefront Settings</h2>
		<LandingPageStorefrontSection v-if="section.subsection.type == 'storefront'" :section="section.subsection" :isTemplate="false" style="width: 100%;" />
	</div>

</section>
</template>


<script>
import AttachmentFileViewer from '@/features/FileUploader/AttachmentFileViewer.vue'
import LandingPageStorefrontSection from '@/features/SalesManagement/LandingPages/LandingPageSections/LandingPageStorefrontSection.vue'
import Tiptap from '@/features/Tiptap/Tiptap.vue'

import FilesAPI from '@/api/FilesAPI.js'
import { default as SFSection } from '@/features/SalesManagement/LandingPages/LandingPageSections/LandingPageStorefrontSection.js'

export default {
	name: 'LandingPageBuiltInSection',


	components: {
		AttachmentFileViewer,
		LandingPageStorefrontSection,
		Tiptap,
	},


	props: {
		section: Object,

		affiliateID: {
			type: Number,
			default: null
		},

		isTemplate: {
			type: Boolean,
			default: true
		},

		isDefault: {
			type: Boolean,
			default: false
		},

	},


	data() {
		return {
			tiptapSaved: true,
		}
	},



	created() { this.initialize() },


	watch: {
		'section.componentName'() { this.initialize() },
		
		async 'section.vars.logoFileID'( fileID ) {
			if( !fileID ) {
				this.section.vars.logoFilePath = null
				return
			}

			const data = await FilesAPI.getPublicFilePath( fileID, 'attached_files' )
			this.section.vars.logoFilePath = data.path
		},

		async 'section.vars.quoteImageFileID'( fileID ) {
			if( !fileID ) {
				this.section.vars.quoteImageFilePath = null
				return
			}

			const data = await FilesAPI.getPublicFilePath( fileID, 'attached_files' )
			this.section.vars.quoteImageFilePath = data.path
		}
	},



	methods: {
		initialize() {
			
			if( this.section.componentName == 'HeroStore' ) {
				
				if( this.section.subsection ) return
				
				this.section.vars.shortName = null
				this.section.vars.greeting = null
				this.section.vars.bgColor = null
				this.section.vars.mainImageURL = null
				this.section.vars.logoFileID = null
				this.section.vars.logoFilePath = null
				
				this.section.vars.quoteImageFileID = null
				this.section.vars.quoteImageFilePath = null
				this.section.vars.quote = null
				

				this.section.subsection = new SFSection()
				this.section.subsection.showTitle = false
				this.section.subsection.showSlashes = false
				this.section.subsection.showGroupTitle = false
				this.section.subsection.showGroupDescription = false

				this.section.subsection.showCouponCode = false
				this.section.subsection.autofillDefaultCoupon = true

				this.section.subsection.sliderMode = true
				this.section.subsection.openByDefault = true
				this.section.subsection.defaultSlideIndex = 0
				this.section.subsection.showComparePrograms = false
			
			} else this.section.subsection = null
		},
		
		update(e) { this.$emit('update'); },
		revert() { this.$emit('revert'); },


		nullifyBlank( varName ) {
			if( this.section.vars[ varName ] === undefined ) return
			if( this.section.vars[ varName ] == '' ) this.section.vars[ varName ] = null
		},


		tiptapSave( html ) {
			this.section.vars.quote = html
			this.tiptapSaved = true
		},
	}
}
</script>


<style scoped>
input, textarea { padding: 0.5em !important; }

.default {
	color: #777;
}
#revert {
	cursor: pointer;
}
</style>
