<template>
<section>

	<section class="flex-row flex-justify-between my-05">
		
		<div class="flex-row flex-align-start flex-justify-start flex-gap-1">
			<SearchBox @search="str => searchString = str" />
			<div>
				<GenericTagSearchDropdown tagType="review" @update="tag => addFilterTag( tag )" ref="filterTagSearch" />
				<TagField :Source="filterTags.tags" :topMargin="false" @remove="tag => filterTags.removeTag( tag )" />
			</div>
		</div>

		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>

			<div class="flex-row flex-align-center flex-gap-05">
				<span v-if="!csvPageBusy" class="icon-file-excel font-size-1-5 color-blue-40" @click="csvDownload( false )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this page (${ reviews.length.toLocaleString() } reviews)`" />
				<span v-else class="font-size-1-5 icon-spinner4 spin1" />
				<span v-if="!csvAllBusy" class="icon-file-excel font-size-1-5 color-red-40" @click="csvDownload( true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of all ${count.toLocaleString()} reviews`" />
				<span v-else class="font-size-1-5 icon-spinner4 spin1" />
			</div>
		</div>

	</section>

	<h2 class="mt-2 mb-0 center">Super Reviews are manually ordered</h2>
	<p class="mt-05 mb-2 center">Drag &amp; drop rows to re-order reviews in the "super reviews" widget on v10 landing pages</p>

	<section v-if="!loading && !error && reviews.length">
    <ObjectTable
      :Source="reviews"
			SortBy="superIndex"
      :Columns='columns'
      :Numbered="true"
			:draggable="true"
      :PageNum='page'
      :PageSize='pageSize'
			:StyleFunction='styleFunc'
      @edit="item => select(item)"
			@reorder="( oldIndex, newIndex ) => reorder( oldIndex, newIndex )"
      ref="objectTable"
    />
    <Paginator v-model="page" @input="initialize()" :numPages="pages" />
  </section>


  <div v-else-if="!loading && !error && !reviews.length" class="NoResults">No Reviews</div>

  <section v-else-if='loading && !error' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

  <section v-else-if="error" class="warning">
		Failed to get records
	</section>

  <StretchModal ref="detailsModal" @close="deselect()" :width="null" padding="3em">
    <template #header>Review #{{ selectedItem.reviewID }}</template>
    <ReviewDetails :review="selectedItem" />
  </StretchModal>

</section>
</template>



<script>
import ReviewDetails from './ReviewDetails.vue'
import GenericTagSearchDropdown from '@/components/utilities/GenericTagSearchDropdown.vue'
import TagField from '@/components/utilities/TagField.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import ReviewsAPI from '@/api/ReviewsAPI.js'
import Review from '@/features/Reviews/Review.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'SuperReviewsDashboard',


	components: {
		ReviewDetails,
		GenericTagSearchDropdown,
		TagField,
		ObjectTable,
		Paginator,
		SearchBox,
		StretchModal,
	},


	data() {
		return {
			reviews: [],
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,

			// CSV download
			csvPageBusy: false,
			csvAllBusy: false,

			searchString: null,
			
			loading: false,
			error: false,

			selectedItem: null,
			filterTags: null,
			
			columns: [
				{
					displayName: "Author",
					propertyName: 'authorName',
					displayFunction: function( item ) {
						return `<div>${item.userID !== null ? '<span class="icon-user mr-05 color-blue-60"></span>' : ''}${item.authorName} ${item.userID ? '(#' + item.userID + ')' : ''}</div><div class="color-gray-50">${item.authorEmail || ''}</div>`
					},
					sortable: false
				},
				{
					displayName: 'Stars',
					propertyName: 'stars',
					displayFunction: function( item ) {
						const prompt = item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt.substring(0,15) + ' ...' : item.starPrompt
						var str = `<div style="font-style: italic; font-size: 0.9em; max-width: 8em;">${prompt || ''}</div>`
						for(var i=0; i < item.stars; i++) str += '<span class="icon-star-full" style="color: orange"></span>'
						return str
					},
					itemTooltip: function( item ) { return item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt : null },
					sortable: false
				},
				{
					displayName: 'Body',
					propertyName: 'body',
					displayFunction: function( item ) {
						var str = item.bodyPrompt ? `<div style="font-style: italic; font-size: 0.9em;">PROMPT: ${item.bodyPrompt || ''}</div>` : ''
						if( item.title ) str = '<div style="font-weight:bold;">' + item.title + '</div>'
						if( item.body ) str += `<div class="color-gray-30 bg-white pa-1 round-05">${ item.body }</div>`
						return str
					},
					style: function( item ) { return 'min-width: min( 30em, 30% );' },
					sortable: false
				},
				{
					displayName: 'TIP <span class="icon-stopwatch" />',
					propertyName: 'minutesInProgram',
					displayFunction: function( item ) {
						return item.hoursInProgramString
					},
					sortable: false
				},
				{
					displayName: 'Aff',
					propertyName: 'affiliates',
					displayFunction: function( item ) {
						const affNames = item.getAffiliateNames( 15 )
						return '<div>' + affNames.join( ',</div><div>' ) + '</div>'
					},
					itemTooltip: function( item ) {
						return item.getAffiliateNames().join( ",\n" )
					},
					sortable: false
				},
				{
					displayName: 'Cust',
					propertyName: 'customerName',
					displayFunction: function( item ) {
						if( !item.customerName ) return item.customerID
						return item.customerName.length > 15 ? item.customerName.substring(0,10) + ' ...' : item.customerName
					},
					itemTooltip: function( item ) { return item.customerName && item.customerName.length > 15 ? item.customerName : null },
					sortable: false
				},
				{
					displayName: 'Lic #',
					propertyName: 'licenseKey',
					displayFunction: function( item ) {
						if( item.licenseKey ) return '<span class="icon-checkmark" style="color: var(--pp10-green);" />'
						return null
					},
					itemTooltip: function( item ) { return item.licenseKey || null },
					sortable: false
				},
				{
					displayName: 'Modal<br>Msg',
					propertyName: 'modalMessageName',
					displayFunction: function( item ) {
					if( item.modalMessageName === null ) return item.modalMessageID ? `Deleted -- #${item.modalMessageID}` : null
						return item.modalMessageName.length > 25 ? item.modalMessageName.substring(0,20) + ' ...' : item.modalMessageName
					},
					itemTooltip: function( item ) { return item.modalMessageName && item.modalMessageName.length > 25 ? item.modalMessageName : null },
					sortable: false
				},
				{
					displayName: 'Time',
					propertyName: 'timestamp',
					displayFunction: function( item ) { return item.timestamp ? item.timestamp.toLocaleString() : '' },
					sortable: false
				},
				{
					displayName: 'Tags',
					propertyname: 'tags',
					displayFunction: function( item ) { return '<div class="font-size-0-9">' + item.tags.join(', ') + '</div>' },
					style: function( item ) { return { minWidth: '100px' } },
					sortable: false
				},
				{
					displayName: 'Published',
					propertyName: 'isPublished',
					displayFunction: function( item ) { return item.isPublished ? '<span class="icon-checkmark message-success" />' : '' },
					sortable: false
				},
				{
					displayName: 'Featured',
					propertyName: 'isFeatured',
					displayFunction: function( item ) { return item.isFeatured ? '<span class="icon-checkmark message-success" />' : '' },
					sortable: false
				},
				{
					displayName: 'Super',
					propertyName: 'isSuper',
					displayFunction: function( item ) { return item.isSuper ? '<span class="icon-checkmark message-success" />' : '' },
					sortable: false
				},
				{
					displayName: 'Rank',
					propertyName: 'rank',
					sortable: false
				},
				{
					displayName: 'Category',
					propertyName: 'category',
					sortable: false
				}
			]
		}
	},



	computed: {
		t() { return Tooltips; },
	},



	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
		'filterTags.tags'() { this.page = 1; this.initialize() },
	},



	created() {
		this.filterTags = new Review()  // Container for tags
		this.initialize()
	},



	methods: {
		async initialize() {

			console.debug("Initializing")

			try {
				this.reviews = []
				this.loading = true
				this.error = false

				const reqBody = this.prepareRequest()

				const pr = await ReviewsAPI.getReviews( reqBody )

				for( let review of pr.data ) this.reviews.push( Review.import(review) )
				this.pages = pr.pages
				this.count = pr.count

			} catch( e ) {
				this.reviews = []

				this.pages = 1
				this.count = 0
				this.error = true
				console.error( e )

			} finally {
				this.loading = false
			}
		},


		async csvDownload( allPages = true ) {
			const req = this.prepareRequest()

			if( allPages ) this.csvAllBusy = true
			else this.csvPageBusy = true

			try {
				await ReviewsAPI.getReviewsCSV( req, allPages )

			} finally {
				this.csvPageBusy = false
				this.csvAllBusy = false
			}
		},


		prepareRequest() {
			const req = new PaginatedRequest( null, true, this.page, this.pageSize, this.searchString )
			if( this.filterTags.tags.length > 0 ) req.tags = [ ...this.filterTags.tags ]
			req.isSuper = true
			return req
		},


		select( rvw ) {
      this.selectedItem = rvw
      this.$refs.detailsModal.open()
    },

    deselect() {
      this.selectedItem = null
      this.$refs.objectTable.deselect()
    },


		addFilterTag( tag ) {
			if( !tag ) return
			this.filterTags.addTag( tag )
			this.$refs.filterTagSearch.clear()
		},


		async reorder( oldIndex, newIndex ) {

			const target = this.reviews[ newIndex ]
			const aboveTarget = this.reviews[ newIndex - 1 >= 0 ? newIndex - 1 : newIndex ]
			if( target.superIndex === null && aboveTarget.superIndex === null && newIndex > 0 ) return
			
			const rvw = this.reviews[ oldIndex ]
			await ReviewsAPI.reorderSuperReview( rvw.reviewID, newIndex )

			// Remove from old position
			this.reviews.splice( oldIndex, 1 )

			// Add to new position
			this.reviews.splice( newIndex, 0, rvw )

			rvw.superIndex = newIndex
		},


		styleFunc( review ) {
			if( review.superIndex === null ) return { backgroundColor: 'var(--pp10-orange-pastel-90)' }
			else return null
		}

	}
}
</script>



<style scoped>

</style>