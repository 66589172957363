<template>
<div class="side-tray" :class='{"expanded": expanded, "collapsed": !expanded}'>

  <ul class="MenuList" :class="{'expanded': expanded}">

    <li @click="mode='dashboard'" :class="{'active':mode==='dashboard'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Dashboard"><span class="icon icon-meter2" /><span v-if="expanded"> Dashboard</span></li>

    <hr />

    <li v-if="hasPrivilege( 'site_admin:users:*' )" @click="mode='users'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='users', 'open' : mode.startsWith('users') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Users">
      <div class="flex-row flex-align-center">
        <span class="icon icon-users" />
        <span v-if="expanded"> Users</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('users') }" />
    </li>
    <ul v-if="hasPrivilege( 'site_admin:users:*' )" class="subList" :class="{ 'show' : mode.startsWith('users') }">
      <li @click="mode='users.usertime'" :class="{'active':mode==='users.usertime'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Time in Program"><span class="icon icon-stopwatch" /><span v-if="expanded"> Time in Program</span></li>
      <li @click="mode='users.sessions'" :class="{'active':mode==='users.sessions'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Study Sessions"><span class="icon icon-book" /><span v-if="expanded"> Study Sessions</span></li>
      <li @click="mode='users.logins'" :class="{'active':mode==='users.logins'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Login Attempts"><span class="icon icon-enter" /><span v-if="expanded"> Logins</span></li>
    </ul>
    <li v-if="hasPrivilege( 'site_admin:license:*' )" @click="mode='licenses'" :class="{'active':mode==='licenses'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Licenses"><span class="icon icon-key" /><span v-if="expanded"> Licenses</span></li>
    <li v-if="hasPrivilege( 'site_admin:courses:*' )" @click="mode='courses'" :class="{'active':mode==='courses'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Courses"><span class="icon icon-books" /><span v-if="expanded"> Courses</span></li>

    <hr>
    <li v-if="hasPrivilege( 'site_admin' )" @click="mode='stats'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='stats', 'open' : mode.startsWith('stats') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Student Statistics">
      <div class="flex-row flex-align-center">
        <span class="icon icon-pie-chart" />
        <span v-if="expanded"> Statistics</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('stats') }" />
    </li>
    <ul v-if="hasPrivilege( 'site_admin' )" class="subList" :class="{ 'show' : mode.startsWith('stats') }">
      <li @click="mode='stats.eknostatbuilder'" :class="{'active':mode==='stats.eknostatbuilder'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="eKno-Stat Report Builder"><span class="icon icon-equalizer" /><span v-if="expanded"> eKno-Stat Builder</span></li>
      <li @click="mode='stats.escores'" :class="{'active':mode==='stats.escores'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="eScore Table"><span class="icon icon-stats-dots" /><span v-if="expanded"> E-Score Table</span></li>
    </ul>
    <hr>

    <li v-if="hasPrivilege( 'site_admin:affiliates:*' )" @click="mode='affiliates'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='affiliates', 'open' : mode.startsWith('affiliates') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Sponsoring Affiliates">
      <div class="flex-row flex-align-center">
        <span class="icon icon-gift" />
        <span v-if="expanded"> Affiliates</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('affiliates') }" />
    </li>
    <ul v-if="hasPrivilege( 'site_admin:affiliates:*' )" class="subList" :class="{ 'show' : mode.startsWith('affiliates') }">
      <li @click="mode='affiliates.landingpages'" :class="{'active':mode==='affiliates.landingpages'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Landing Pages"><span class="icon icon-airplane" /><span v-if="expanded" style="text-align: left;"> Landing Page<br>Templates</span></li>
      <li @click="mode='affiliates.storefronts'" :class="{'active':mode==='affiliates.storefronts'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Storefronts"><span class="icon icon-cart" /><span v-if="expanded"> Storefronts</span></li>
    </ul>

    <li v-if="hasPrivilege( 'site_admin:customers:*' )" @click="mode='customers'" :class="{'active':mode==='customers'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Customers"><span class="icon icon-office" /><span v-if="expanded"> Customers</span></li>
    <li v-if="hasPrivilege( 'site_admin:poc:*' )" @click="mode='contacts'" :class="{'active':mode==='contacts'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Contacts"><span class="icon icon-address-book" /><span v-if="expanded"> Contacts (cust + aff)</span></li>

    <hr>

    <li v-if="hasPrivilege( 'site_admin:orders:*' )" @click="mode='orders'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='orders', 'open' : mode.startsWith('orders') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Orders">
      <div class="flex-row flex-align-center">
        <span class="icon icon-coin-dollar" />
        <span v-if="expanded"> Orders</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('orders') }" />
    </li>
    <ul class="subList" :class="{ 'show' : mode.startsWith('orders') }">
      <li @click="mode='orders.summary'" :class="{'active':mode==='orders.summary'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Order Summary"><span class="icon icon-calendar" /><span v-if="expanded"> Summary</span></li>
      <!-- <li @click="mode='orders.monthly'" :class="{'active':mode==='orders.monthly'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Orders By Month"><span class="icon icon-calendar" /><span v-if="expanded"> Summary (Month)</span></li> -->
      <hr>
      <li @click="mode='orders.transactions'" :class="{'active':mode==='orders.transactions'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Transactions"><span class="icon icon-barcode" /><span v-if="expanded"> Transactions</span></li>
      <li @click="mode='orders.subscriptions'" :class="{'active':mode==='orders.subscriptions'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Subscriptions"><span class="icon icon-credit-card" /><span v-if="expanded"> Subscriptions</span></li>
      <hr>
      <li @click="mode='orders.create'" :class="{'active':mode==='orders.create'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Create Invoice"><span class="icon icon-pencil" /><span v-if="expanded"> Create Invoice</span></li>
    </ul>

    <li v-if="hasPrivilege( 'site_admin:coupon_codes:*' )" @click="mode='coupons'" :class="{'active':mode==='coupons'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Coupon Codes"><span class="icon icon-ticket" /><span v-if="expanded"> Coupons</span></li>
    <li v-if="hasPrivilege( 'site_admin:product:*' )" @click="mode='products'" :class="{'active':mode==='products'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Products"><span class="icon icon-briefcase" /><span v-if="expanded"> Products</span></li>
    <li v-if="hasPrivilege( 'site_admin:sales_reps:*' )" @click="mode='salesreps'" :class="{'active':mode==='salesreps'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Sales Reps"><span class="icon icon-man" /><span v-if="expanded"> Sales Reps</span></li>

    <hr>

    <li v-if="hasPrivilege( 'messaging:*' )" @click="mode='messages.messagecenter'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='messages.messagecenter', 'open' : mode.startsWith('messages') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Message Center">
      <div class="flex-row flex-align-center">
        <span class="icon icon-envelop" />
        <span v-if="expanded"> Message Center</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('messages') }" />
    </li>
    <ul v-if="hasPrivilege( 'messaging:*' )" class="subList" :class="{ 'show' : mode.startsWith('messages') }">
      <li v-if="hasPrivilege( 'messaging:send:*' )" @click="mode='messages.compose'" :class="{'active':mode==='messages.compose'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Compose"><span class="icon icon-quill" /><span v-if="expanded"> <b>Compose</b></span></li>
      <li v-if="hasPrivilege( 'messaging:view_blasts:*' )" @click="mode='messages.drafts'" :class="{'active':mode==='messages.drafts'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Drafts &amp; Blasts"><span class="icon icon-quill" /><span v-if="expanded"> Drafts &amp; Blasts</span></li>
      <li v-if="hasPrivilege( 'messaging:view_sent:*' )" @click="mode='messages.sentmessages'" :class="{'active':mode==='messages.sentmessages'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Sent Messages"><span class="icon icon-envelop" /><span v-if="expanded"> Sent Messages</span></li>
      <hr>
      <li v-if="hasPrivilege( 'messaging:send:*' )" @click="mode='messages.newtemplates'" :class="{'active':mode==='messages.newtemplates'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Templates"><span class="icon icon-profile" /><span v-if="expanded"> Templates</span></li>
      <li v-if="hasPrivilege( 'messaging:lists:*' )" @click="mode='messages.blastlists'" :class="{'active':mode==='messages.blastlists'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Contact Lists"><span class="icon icon-files-empty" /><span v-if="expanded"> Contact Lists</span></li>
      <hr>
      <li v-if="hasPrivilege( 'messaging:unsubscribes:*' )" @click="mode='messages.unsubscribes'" :class="{'active':mode==='messages.unsubscribes'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Unsubscribes"><span class="icon icon-user-minus" /><span v-if="expanded"> Unsubscribes</span></li>
      <hr />
      <li v-if="hasPrivilege( 'org:license:assign' )" @click="mode='messages.scheduled'" :class="{'active':mode==='messages.scheduled'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Scheduled Registration Emails"><span class="icon icon-envelop" /><span v-if="expanded"> Scheduled Emails</span></li>
      <li v-if="hasPrivilege( 'site_admin' )" @click="mode='messages.templates'" :class="{'active':mode==='messages.templates'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Email Templates"><span class="icon icon-mail3" /><span v-if="expanded"> Email Templates</span></li>
    </ul>
    
    <li v-if="hasPrivilege( 'modal_messages:*' )" @click="mode='modalmessages'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='modalmessages', 'open' : mode.startsWith('modalmessages') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Modal Messages">
      <div class="flex-row flex-align-center">
        <span class="icon icon-enlarge" />
        <span v-if="expanded"> Modal Messages</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('modalmessages') }" />
    </li>
    <ul v-if="hasPrivilege( 'modal_messages:*' )" class="subList" :class="{ 'show' : mode.startsWith('modalmessages') }">
      <li @click="mode='modalmessages.views'" :class="{'active':mode==='modalmessages.views'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Views"><span v-if="expanded"> Views</span></li>
      <li @click="mode='modalmessages.actions'" :class="{'active':mode==='modalmessages.actions'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Actions"><span v-if="expanded"> Actions</span></li>
      <li @click="mode='modalmessages.responses'" :class="{'active':mode==='modalmessages.responses'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Responses"><span v-if="expanded"> Responses</span></li>
    </ul>

    <hr>
    
    <li v-if="hasPrivilege( 'reviews:*' )" @click="mode='reviews'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='reviews', 'open' : mode.startsWith('reviews') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Customer Reviews">
      <div class="flex-row flex-align-center">
        <span class="icon icon-star-half" />
        <span v-if="expanded"> Reviews</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('reviews') }" />
    </li>
    <ul v-if="hasPrivilege( 'reviews:*' )" class="subList" :class="{ 'show' : mode.startsWith('reviews') }">
      <li @click="mode='reviews.super'" :class="{'active':mode==='reviews.super'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Super Reviews"><span class="icon icon-star-full" /><span v-if="expanded"> Super Reviews</span></li>
      <li @click="mode='reviews.educator'" :class="{'active':mode==='reviews.educator'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Educator Reviews"><span class="icon icon-user-tie" /><span v-if="expanded"> Educator Reviews</span></li>
    </ul>

    <li v-if="hasPrivilege( 'site_admin:feedback:*' )" @click="mode='bugreports'" :class="{'active':mode==='bugreports'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Bug Reports">
      <span class="icon icon-bug" />
      <span v-if="expanded">Bug Reports</span>
      <div v-if='numUnreadBugReports > 0' class='unread-dot flex-row flex-align-center flex-justify-center'>{{ numUnreadBugReports }}</div>
    </li>

    <li v-if="hasPrivilege( 'site_admin:demo_request:*' )" @click="mode='demos'" :class="{'active':mode==='demos'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Demo Requests"><span class="icon icon-tv" /><span v-if="expanded"> Demo Requests</span></li>

    <hr>
    <li v-if="hasPrivilege( 'site_admin:media_library:*' )" @click="mode='library'" :class="{'active':mode==='library'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Media Library"><span class="icon icon-film" /><span v-if="expanded"> Media Library</span></li>
    <li v-if="hasPrivilege( 'system:proxy:*' )" @click="mode='proxy'" :class="{'active':mode==='proxy'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Proxy"><span class="icon icon-shuffle" /><span v-if="expanded"> Proxy</span></li>
    <li v-if="hasPrivilege( 'site_admin' )" @click="mode='sys.logs'" class="flex-row flex-align-center flex-justify-between" :class="{ 'active':mode==='sys', 'open' : mode.startsWith('sys') }" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="System">
      <div class="flex-row flex-align-center">
        <span class="icon icon-cog" />
        <span v-if="expanded"> System</span>
      </div>
      <span v-if="expanded" class="folderIcon icon-folder" :class="{ 'icon-folder-open' : mode.startsWith('sys') }" />
    </li>
    <ul v-if="hasPrivilege( 'site_admin:*' )" class="subList" :class="{ 'show' : mode.startsWith('sys') }">
      <li @click="mode='sys.logs'" :class="{'active':mode==='sys.logs'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Event Logs"><span class="icon icon-floppy-disk" /><span v-if="expanded"> Event Logs</span></li>
      <li @click="mode='sys.systemStatus'" :class="{'active':mode==='sys.systemStatus'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="System Status"><span class="icon icon-meter" /><span v-if="expanded"> System Status</span></li>
      <hr>
      <li @click="mode='sys.tools'" :class="{'active':mode==='sys.tools'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Tools"><span class="icon icon-wrench" /><span v-if="expanded"> Tools</span></li>
      <li @click="mode='sys.screens'" :class="{'active':mode==='sys.screens'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Login Screens"><span class="icon icon-camera" /><span v-if="expanded"> Login Screens</span></li>
      <li @click="mode='sys.quotes'" :class="{'active':mode==='sys.quotes'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Quotes"><span class="icon icon-quotes-left" /><span v-if="expanded"> Quotes</span></li>
      <li @click="mode='sys.colleges'" :class="{'active':mode==='sys.colleges'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Colleges"><span class="icon icon-library" /><span v-if="expanded"> Colleges</span></li>
      <hr>
      <li @click="mode='sys.roles'" :class="{'active':mode==='sys.roles'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Roles"><span class="icon icon-user-plus" /><span v-if="expanded"> Roles</span></li>
      <li @click="mode='sys.features'" :class="{'active':mode==='sys.features'}" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Features"><span class="icon icon-user-check" /><span v-if="expanded"> Features</span></li>
    </ul>

    <hr>
    
    <li @click="logout()" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" tooltip="Log Out"><span class="icon icon-exit" /><span v-if="expanded"> Log Out</span></li>

  </ul>
</div>
</template>



<script>
import FeedbackAPI from '@/api/FeedbackAPI.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
  name: 'SideTray',


  props: {
    open: {
      type: Boolean,
      default: true
    },
  },


  data() {
    return {
      expanded: this.open,
      mode: '',
      modeGuard: false,
      numUnreadBugReports: 0,
    }
  },


  watch: {
    open(value) { this.expanded = value },
    mode( value ) {
      if( !this.modeGuard ) this.$store.state.pipes.send( 'teleport', { mode: value } )
      this.modeGuard = false

      if( value == 'bugreports' ) this.getUnreadBugReportCount()
    }
  },


  created() {
    this.$store.state.pipes.listen( 'teleport', ( { mode } ) => {
      this.modeGuard = true
      this.mode = mode
    })

    this.getUnreadBugReportCount()
  },


  methods: {

    async getUnreadBugReportCount() {
      if( !this.hasPrivilege( 'site_admin:feedback:*' ) ) return
      this.numUnreadBugReports = await FeedbackAPI.getUnreadFeedbackCount()
    },

    showTip(ev){
      if(!this.expanded) Tooltips.showTooltip(ev, "right")
    },
    hideTip(ev){
      return Tooltips.hideTooltip(ev)
    },

    async logout(){
      await this.$store.dispatch('logout');
    },

    hasPrivilege( featureName ) {
      return this.$store.state.userRoles.hasAccess( featureName )
    }
  }
}
</script>

<style scoped>
.st-header {
  background-color: var(--ekno-blue);
  color: whitesmoke;
}
.st-header p {
  font-weight: bold;
}
.side-tray {
  /* box-shadow: 1px 0 10px #ccc; */
  background-color: #f5f8fc;
  /* background-color: #eee; */
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  /* max-height: calc(100vh - 52px); */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.side-tray::-webkit-scrollbar {
  display: none;
}
.side-tray.expanded {
  min-width: 250px;
  width: 250px;
  overflow: auto;
}
.collapsed {
  width: 48px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0.5em 0;
}
li {
  display: flex;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  padding: 0.25em 0.25em 0.25em 0.6em;
  text-align: center;
  border: 2px solid rgba( 255,255,255, 0 );
  border-left: none;
  border-radius: 0 1em 1em 0;
  margin-right: 0.75em;
  color: var(--pp10-gray-35);
}
li:hover {
  color: black;
  background-color: var(--ekno-blue-90);
}

.folderIcon {
  font-size: 0.75em;
  color: #88a;
  margin-right: 0.75em;
  margin-left: 0.5em;
}
li.active .folderIcon {
  color: white;
}
li.open:not(.active) .folderIcon {
  color: var(--ekno-blue-50);
}

.MenuList:not(.expanded) li {
  border: none;
  border-radius: 0;
  margin: 0;
}
.MenuList.expanded li .icon {
  color: #88a;
  font-size: 1.3rem;
  vertical-align: baseline;
  margin-right: 0.5em;
}
.MenuList.expanded li:hover .icon,
.MenuList.expanded li.open .icon {
  color: var(--ekno-blue);
}

.MenuList.expanded li:hover:not(.active),
.MenuList.expanded li.open:not(.active) {
  color: var(--ekno-blue-20);
}
.MenuList:not(.expanded) li:hover {
  background-color: var(--ekno-blue-35);
  color:white;
}
hr {
  border: none;
  width: 100%;
  min-height: 1px;
  background-color: #ccc;
  background: linear-gradient(to right, rgba( 128, 128, 156, 0.7 ) 0%, rgba( 128, 128, 156, 0 ) 100% );
}


.MenuList:not(.expanded) {
  font-size: 1.2em;
}

li.active {
  font-weight: bold;
  background-color: var(--ekno-blue-35);
  color: white;
  /* border-color: skyblue; */
}
li.open:not(.active) {
  border-color: var(--ekno-blue-35);
  border-left: none;
}
.MenuList.expanded li.active .icon {
  color: white;
}

.subList {
  max-height: 0;
  opacity: 0;
  margin: 0;
  overflow: hidden;
  transition: 0.25s;
}

.subList.show {  
  background-color: var(--ekno-blue-90);
  padding: 0.5em 0;
  margin: 0 0 1em 0;
  max-height: initial;
  opacity: 1;
  transition: 0.25s;
}
.expanded .subList.show {
  margin: 0 1em 0.5em 1em;
  padding: 0.5em 0 0.5em 0;
  border: 1px solid var(--ekno-blue-90);
  border-left: 1px solid var(--ekno-blue-75);
  background-color: var(--ekno-blue-95);
  border-radius: 0 1em 1em 1em;
  /* box-shadow: 0 0 0.75em -0.3em var(--ekno-blue-10); */
}

.expanded .subList li {
  margin-right: 0.5em;
}
.expanded .subList li.active {
  border-color: var(--ekno-blue-35);
}

.expanded .subList hr {
  min-height: 1px;
  background: linear-gradient(to right, rgba( 128, 128, 156, 0 ) 0%, rgba( 128, 128, 156, 0.4 ) 50%, rgba( 128, 128, 156, 0 ) 100% );

}

.unread-dot {
  margin-left: 1em;
  height: 1.5em;
  width: 1.5em;
  padding: 0.25em;

  color: white;
  font-size: 0.75em;
  font-weight: bold;
  background-color: var(--pp10-orange-50);
  border-radius: 50%;
}
</style>
