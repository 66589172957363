<template>
<section>

	<section class="flex-row flex-align-stretch flex-gap-2 flex-wrap">
		
		<div class="flex-grow">
			<div class="flex-row flex-align-start flex-justify-between">

				<div>
					<div><strong>Date:</strong> {{ date }}</div>
					<div><strong>Type:</strong> {{ FeedbackObj.type }}</div>
					<div><strong>Importance:</strong> {{ FeedbackObj.importance }}</div>
				</div>
				
				<div>
					state:
					<select v-model="FeedbackObj.state">
						<option value="open">open</option>
						<option value="assigned">assigned</option>
						<option value="responded">responded</option>
						<option value="closed">closed</option>
					</select>
				</div>
			</div>
	
			
	
			<div class='mt-3'>
				<div class="underline"><b>Title:</b> {{ FeedbackObj.title }}</div>
				<div class="mt-05">&ldquo;{{ FeedbackObj.body }}&rdquo;</div>
			</div>
	
			<div class='mt-3 font-small'>
				<div>{{ userFullName }}</div>
				<div>{{ FeedbackObj.email }}</div>
				<div>License key: {{ FeedbackObj.license }}</div>
			</div>

			<div class="mt-2"><button class="pillButton" @click="sendMessage()">Send Email Reply</button></div>
		</div>

		<div class="flex-grow flex-column" style="min-width: 300px;">
			<div class="bold">Notes:</div>
			<textarea class="w-100 flex-grow" v-model="FeedbackObj.notes" />
		</div>
	</section>


	<!-- Tabs -->
	<section class="Tab mt-2">
		<button @click="mode = 'ppInfo'" :class="{ active: mode === 'ppInfo' }">PowerPrep Info</button>
		<button @click="mode = 'runtime'" :class="{ active: mode === 'runtime' }">Runtime Environment</button>
		<button v-if="FeedbackObj.hasScreenshot" @click="mode = 'screenshot'" :class="{ active: mode === 'screenshot' }">Screenshot</button>
	</section>

	<section v-if="mode == 'ppInfo'">
		<p><strong>PowerPrep Technical Information</strong></p>
		<p v-for="(property, index) in FeedbackObj.powerPrepInfo" :key="property">
			&nbsp; &nbsp; - {{index}}: {{property}}
		</p>
	</section>

	<section v-else-if="mode == 'runtime'">
		<p><strong>Runtime Environment</strong></p>
		<p v-for="(property, index) in FeedbackObj.browserInfo" :key="property">
			&nbsp; &nbsp; - {{index}}: {{property}}
		</p>
	</section>

	<section v-else-if="mode == 'screenshot'" class="mt-1 center">
		<img :src="imgSrc" />
	</section>

</section>
</template>



<script>
import FeedbackAPI from '@/api/FeedbackAPI.js'
import {base_url} from "@/Config.js"
import MessageBlast from '@/features/EmailCommunication/MessageCenter/MessageBlast';

export default {
	name: 'FeedbackDetails',

	props: {
		FeedbackObj: Object
	},

	
	data() {
		return {
			mode: 'ppInfo',
			notesTimeoutID: null,
		}
	},


	computed: {
		userFullName() {
			return `${this.FeedbackObj.firstName} ${this.FeedbackObj.lastName}`;
		},

		date() {
			if(!this.FeedbackObj.timestamp) return ''
			return this.FeedbackObj.timestamp.toLocaleString()
		},

		imgSrc() { return `${base_url}/FeedbackAPI/getScreenshot/${this.FeedbackObj.id}` },
	},


	watch: {
		'FeedbackObj.notes'() {
			clearTimeout( this.notesTimeoutID )
			this.notesTimeoutID = setTimeout( () => {
				FeedbackAPI.patchFeedback( { id: this.FeedbackObj.id, key: 'notes', value: this.FeedbackObj.notes } )
			}, 500)
		}
	},


	methods: {
		sendMessage() {
			
			const draft = new MessageBlast()
			draft.recipients.push( { name: this.FeedbackObj.firstName, address: this.FeedbackObj.email, userID: this.FeedbackObj.userID } )
			draft.message = {
				subject: `Re: your bug report: "${this.FeedbackObj.title}"`,
				htmlBody: `<p>Hi ${this.FeedbackObj.firstName},</p><p>We've received your message:</p><blockquote><i>${this.FeedbackObj.body}</i></blockquote>`,
				plainBody: `Hi ${this.FeedbackObj.firstName},\n\nWe've received your message:\n\n${this.FeedbackObj.body}\n\n`,
			}

			draft.fromID = this.$store.state.user.userID
			// draft.fromName = `${this.$store.state.user.firstName} at eKnowledge`
			// draft.fromEmail = this.$store.state.user.emailAddress

			draft.fromEmailUsername = `${this.$store.state.user.firstName} at eKnowledge`
			draft.fromEmailMailbox = 'accounts'

			draft.deliverEmail = true

			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { draftIn: draft }, header: `Compose Message` } )
		},
	}

}
</script>



<style scoped>

</style>
