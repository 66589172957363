import { render, staticRenderFns } from "./ReviewsDashboard.vue?vue&type=template&id=46545792&scoped=true"
import script from "./ReviewsDashboard.vue?vue&type=script&lang=js"
export * from "./ReviewsDashboard.vue?vue&type=script&lang=js"
import style0 from "./ReviewsDashboard.vue?vue&type=style&index=0&id=46545792&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46545792",
  null
  
)

export default component.exports