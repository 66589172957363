<template>
<section>
	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} contacts on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='true'
				@edit='item => select( item )'
				@sort='prop => sort( prop )'
				@delete='item => showDeleteItem( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>



	<StretchModal ref="contactModal" padding="1em" maxWidth="300px" @close="deselect()">
		<template #header>Template: {{ selectedItem ? selectedItem.name : '' }}</template>
		<div v-if="selectedItem && selectedItem.description">Description: {{ selectedItem.description }}</div>
		<div class="mt-1">Subject: {{ selectedItem ? selectedItem.subject : '' }}</div>
		<div class="mt-1 border-top" v-html="selectedItem ? selectedItem.body : ''" />
		<div class="mt-1 flex-row flex-justify-end"><button class="pillButton" @click="useTemplate()">Edit / Use this Template</button></div>
	</StretchModal>

	<ConfirmDialog ref="confirmDelete" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>{{ itemToDelete.name }}</b>?</div>
	</ConfirmDialog>

</section>
</template>



<script>
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import MessageTemplate from './MessageTemplate.js'
import StringUtils from '@/libraries/StringUtils.js'

export default {
	name: 'MessageTemplateList',

	components: {
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		ConfirmDialog,
	},



	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: 'dateModified',
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: false,

			selectedItem: null,
			itemToDelete: null,

			columns: [
				{
					displayName: 'Name',
					propertyName: 'name',
					sortable: true,
				},
				{
					displayName: 'Description',
					propertyName: 'description',
					sortable: true,
				},
				{
					displayName: 'Subject',
					propertyName: 'subject',
					sortable: true,
				},
				{
					displayName: 'HTML Body',
					propertyName: 'body',
					displayFunction: function( item ) {
						return StringUtils.ellipsify( item.body, 50 )
					},
					sortable: true,
				},
				{
					displayName: 'Plaintext Body',
					propertyName: 'altBody',
					displayFunction: function( item ) {
						return StringUtils.ellipsify( item.altBody, 50 )
					},
					sortable: true,
				},
				{
					displayName: 'Date Created',
					propertyName: 'dateCreated',
					displayFunction: ( item ) => { return item.dateCreated ? item.dateCreated.toLocaleString() : null },
					sortable: true,
				},
				{
					displayName: 'Date Modified',
					propertyName: 'dateModified',
					displayFunction: ( item ) => { return item.dateModified ? item.dateModified.toLocaleString() : null },
					sortable: true,
				},
			],
		}
	},



	created() { this.initialize() },
	
	
	
	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},



	methods: {

		async initialize() {
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const pr = await MessageCenterAPI.getMessageTemplates( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( MessageTemplate.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}

		},


		select( item ) {
			this.selectedItem = item
			this.$refs.contactModal.open()
		},

		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},


		showDeleteItem( item ) {
			this.itemToDelete = item
			this.$refs.confirmDelete.open()
		},

		async deleteItem() {
			await MessageCenterAPI.deleteMessageTemplate( this.itemToDelete.id )
			this.items = this.items.filter( elem => elem.id != this.itemToDelete.id )
			this.itemToDelete = null
		},


		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.initialize()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'dateCreated' ||
				prop === 'dateModified'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.initialize()
		},


		useTemplate() {
			if( !this.selectedItem ) return
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { templateIn: this.selectedItem }, header: `Compose Message` } )
		}

	}


}
</script>



<style scoped>

</style>